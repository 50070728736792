import { RouteRecordRaw } from 'vue-router';
import { ShiftsRoutesEnum } from './ShiftsRoutesEnum';

const ShiftsPage   = () => import(/* webpackChunkName: "Shifts-module" */ '../pages/shifts/ShiftsPage.vue');
const ShiftPage    = () => import(/* webpackChunkName: "Shifts-module" */ '../pages/shift/ShiftPage.vue');
const ShiftData    = () => import(/* webpackChunkName: "Shifts-module" */ '../pages/shift/ShiftData/ShiftData.vue');
const ShiftTickets = () => import(/* webpackChunkName: "Shifts-module" */ '../pages/shift/ShiftTickets/ShiftTickets.vue');

const routes:  Array<RouteRecordRaw> = [
    {
        path      : '/shifts',
        name      : ShiftsRoutesEnum.SHIFTS_LIST,
        component : ShiftsPage,
        meta: {
            tabTitle: "Work Requests",
			breadCrumbItems: [
				{label: 'Work Requests'},
			]
		}
    },
    {
        path      : '/shifts/:shiftId',
        props     : true,
        component : ShiftPage,
        meta: {
			breadCrumbItems: [
				{
                    label: 'Work Requests', 
                    to: { name: ShiftsRoutesEnum.SHIFTS_LIST} 
                },
                { label: 'Work Request details' },
			]
		},
        children: [
            {
                path      : '',
                name      : ShiftsRoutesEnum.SHIFTS_DETAIL,
                component : ShiftData,
                meta: {
                    tabTitle: 'Work Request Sheet'
                }
            },
            {
                path      : 'status',
                name      : ShiftsRoutesEnum.SHIFTS_DETAIL_TICKETS,
                component : ShiftTickets,
                meta: {
                    tabTitle: 'Shift Status'
                }
            },
        ]
    },
    
];

export { routes };
export { ShiftsRoutesEnum };