import { LoginData, LoginResponse, ResetPasswordData, SendResetPasswordData } from "@/model/api/Auth";
import { User } from "@/model/api/User";
import { HttpService } from './base/http.service';

class AuthService extends HttpService {

    private readonly endPoint = `auth`;


    public signup(data: any): Promise<LoginResponse> {
        return this.post<LoginResponse>(`${this.endPoint}/register`, data);
    }

    public login(data: LoginData): Promise<LoginResponse> {
        return this.post<LoginResponse>(`${this.endPoint}/login`, data);
    }

    public logout() {
        return this.post(`${this.endPoint}/logout`);
    }

    public refreshToken() {
        return this.get(`${this.endPoint}/refresh-token`);
    }

    public sendResetPassword(data: SendResetPasswordData) {
        return this.post(`${this.endPoint}/reset-password/send`, data);
    }

    public setResetPassword(data: ResetPasswordData) {
        return this.post(`${this.endPoint}/reset-password/set`, data);
    }

    public me(): Promise<User> {
        return this.get<User>(`${this.endPoint}/me`);
    }

    public updateMe(me: User): Promise<User> {

        // const formData = new FormData();
        // formData.append("name", me.name);
        // formData.append("surname", me.surname);

        // formData.append("email", me.email);

        // if (me.password && me.confirmPassword) {
        //     formData.append("password", me.password);
        //     formData.append("confirm_password", me.confirmPassword);
        // }

        // const options = {
        //     headers: {
        //         'Content-Type': 'multipart/form-data'
        //     }
        // };

        return this.post<User>(`${this.endPoint}/me`, me);
    }
}

export const authService = new AuthService();



