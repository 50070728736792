import 'reflect-metadata';
import "@/utils/collections";

import "@/socket";

import { Loader } from "@googlemaps/js-api-loader";

import { createApp, Plugin } from 'vue'

// BOOTSTRAP COMPONENT
import MainRouter from './MainRouter.vue';

// STYLES
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import '@/assets/primevue/theme/theme-libera.scss';
import '@/assets/primevue/layout/css/layout-libera.scss';

// CUSTOM SCSS
import './app.scss';

// CUSTOM PLUGINS
import {
    ConfigPlugin,
    LocalstoragePlugin,
    MessagesPlugin,
    UtilityPlugin
} from '@/plugins';

// VUE I18N https://vue-i18n.intlify.dev/
import i18n from './i18n'

// VUE ROUTER https://next.router.vuejs.org/
import router from './router'

// VUE STORE https://next.vuex.vuejs.org/
import store, { key_store } from './store'

import { initPrimeVue } from '@/primevue';
import { initFontawesome } from '@/fontawesome';

// VEE-VALIDATE
import '@/vee-validate';

// UTILS
import { logAppVersion } from './utils/utils';

import { ModulesEnum } from './store/modules';

// MODULES
import { MainRoutesEnum }           from '@/router/MainRoutesEnum';
import { ModuleOptions }            from '@/modules/common/ModuleOptions';
import { AuthModule }               from '@/modules/auth';
import { AuthRoutesEnum }           from '@/modules/auth/router';
import { UsersModule }              from '@/modules/users';
import { ZonesModule }              from "@/modules/zones";
import { ShiftsModule }             from "@/modules/shifts";
import { CustomShiftsModule }             from "@/modules/custom-shifts";
import { ContractsModule }          from '@/modules/contracts';
import { MaterialsModule }          from '@/modules/materials';
import { CompaniesModule }          from '@/modules/companies';
import { TicketsModule }            from '@/modules/tickets';
import { LiveViewModule }           from '@/modules/live-view';
import { registerGlobalComponents } from '@/components/globalComponents';
import { configuration }            from "@plugins/Config-plugin";
import { localStorageSVC }          from '@plugins/LocalStorage-plugin';
// SERVICES 
import { authService } from '@services/auth.service';
import { TextModule } from './modules/text';

logAppVersion();

const app = createApp(MainRouter);

const modules: { module: Plugin, options: ModuleOptions }[] = [
    { module: AuthModule,           options: { storeName: ModulesEnum.AUTH, } },
    { module: UsersModule,          options: { parentRoute: MainRoutesEnum.AVALON_TEMPLATE, storeName: ModulesEnum.USERS } },
    { module: ZonesModule,          options: { parentRoute: MainRoutesEnum.AVALON_TEMPLATE } },
    { module: ShiftsModule,         options: { parentRoute: MainRoutesEnum.AVALON_TEMPLATE } },
    { module: CustomShiftsModule,   options: { parentRoute: MainRoutesEnum.AVALON_TEMPLATE } },
    { module: ContractsModule,      options: { parentRoute: MainRoutesEnum.AVALON_TEMPLATE } },
    { module: MaterialsModule,      options: { parentRoute: MainRoutesEnum.AVALON_TEMPLATE } },
    { module: TicketsModule,        options: { parentRoute: MainRoutesEnum.AVALON_TEMPLATE } },
    { module: LiveViewModule,       options: { parentRoute: MainRoutesEnum.AVALON_TEMPLATE } }, //TODO: STORE ?!
    { module: TextModule,           options: { parentRoute: MainRoutesEnum.AVALON_TEMPLATE } },
    { module: CompaniesModule,      options: { parentRoute: MainRoutesEnum.AVALON_TEMPLATE } },
];

modules.forEach(({ module, options }) => {
    options.router = router;
    options.store = store;
    app.use(module, options);
});

app.use(i18n);
app.use(store, key_store);

app.use(ConfigPlugin);
app.use(LocalstoragePlugin);
app.use(MessagesPlugin);
app.use(UtilityPlugin);

initPrimeVue(app);
initFontawesome(app);

registerGlobalComponents(app);

app.config.errorHandler = (error: any, vm, info) => {
    console.error(error);
    console.info(info);

    if (error?.status === 401) {
        localStorageSVC.clearToken(); 
        router.replace({name: AuthRoutesEnum.SIGN_IN});
    }

    vm.$errorMessage(null, "Unhandled error, retry");
};

async function loadGoogle(){
    if (!window.google) {
        const mapLoader = new Loader({
            apiKey    : configuration.mapApiKey,
            version   : configuration.mapVersion,
            libraries : ["places"],
            language  : "en",
        });

        await mapLoader.load();
    }
}

async function initUser(){
    if ( localStorageSVC.token ) {
        try {
            const response = await authService.me();

            

            return store.commit(`${ModulesEnum.AUTH}/setMe`, response);
        } catch (error) {
            localStorageSVC.clearToken();
        }
    }
}

const init = Promise.all([
    loadGoogle(), 
    initUser()
]);

init.then(() => {
    app.use(router);
    app.mount('#app')
});


