import { RouteRecordRaw } from 'vue-router';
import { UsersRoutesEnum } from './UsersRoutesEnum';

const UsersPage 	= () => import(
	/* webpackChunkName: "Users-module" */ '../pages/users/UsersPage.vue');
const UserPage 		= () => import(
	/* webpackChunkName: "Users-module" */ '../pages/user/UserPage.vue');

const AdminsPage	= () => import(
	/* webpackChunkName: "Users-module" */ '../pages/admins/AdminsPage.vue');
const AdminPage	 	= () => import(
	/* webpackChunkName: "Users-module" */ '../pages/admin/AdminPage.vue');


const routes: Array<RouteRecordRaw> = [
	
	{
		path     : '/users',
		name     : UsersRoutesEnum.USERS_LIST,
		component: UsersPage,
		meta	 : {
			breadCrumbItems: [
				{label: 'User list'},
			]
		}
	},
	{
		path     : '/users/:userId',
		name     : UsersRoutesEnum.USER_DETAIL,
		props    : true,
		component: UserPage,
		meta	 : {
			breadCrumbItems: [
				{label: 'User list', to: { name: UsersRoutesEnum.USERS_LIST }},
				{label: 'User detail'},
			]
		}
	},

	

	{
		path     : '/admins',
		name     : UsersRoutesEnum.ADMINS_LIST,
		component: AdminsPage,
		meta	 : {
			breadCrumbItems: [
				{label: 'Admin list'},
			]
		}
	},
	{
		path     : '/admins/:userId',
		name     : UsersRoutesEnum.ADMINS_DETAIL,
		props    : true,
		component: AdminPage,
		meta	 : {
			breadCrumbItems: [
				{label: 'Admin list', to: { name: UsersRoutesEnum.ADMINS_LIST }},
				{label: 'Admin detail'},
			]
		}
	},

];

export { routes };
export { UsersRoutesEnum };