
import { AuthRoutesEnum } from '@/modules/auth/router';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import { guards, tabTitle } from './guards';
import { MainRoutesEnum } from './MainRoutesEnum';

const AvalonTemplate      = () => import(/* webpackChunkName: "avalon-template" */ '@/templates/avalon-template/AvalonTemplate.vue');
const ProfilePage         = () => import(/* webpackChunkName: "avalon-template" */ '@/modules/app/pages/Profile/ProfilePage.vue');

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/dashboard'
  },

  {
    path: '/',
    component: AvalonTemplate,
    name: MainRoutesEnum.AVALON_TEMPLATE,
    children: [
      {
        path: '/dashboard',
        name: MainRoutesEnum.HOME,
        component:  () => import('@/modules/app/pages/DashboardPage.vue'),
        meta	 : {
          tabTitle: 'Dashboard',
          breadCrumbItems: [
            {label: 'Dashboard'},
          ]
        },
      },
      {
          path: '/profile',
          name: AuthRoutesEnum.CURRENT_USER,
          component: ProfilePage,
          props: true,
          meta: {
              tabTitle: 'Profilo',
              breadCrumbItems: [
                {label: 'Profilo'},
              ]
          }
      },
    ],
    meta: {
      requiresAuth: true
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

guards(router);
tabTitle(router);

export default router
