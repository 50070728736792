/**
 * NB:
 * È fondamentale che ciascun gruppo di rotte sia identificabile dal prefisso.
 * 
 * Es: le rotte degli utenti devono iniziare con "users",
 * sopratutto se si raggiungono tramite la tabella utenti.
 * Altrimenti al rientro su di essa vengono cancellati i filtri
 */
export enum UsersRoutesEnum{
    USER_PREFIX = 'users',
    USERS_LIST  = 'users-list',
    USER_DETAIL = 'users-detail',
    
    ADMINS_PREFIX = 'admins',
    ADMINS_LIST   = 'admins-list',
    ADMINS_DETAIL = 'admins-detail',

}