import { RouteRecordRaw } from 'vue-router';
import { TicketsRoutesEnum } from './TicketsRoutesEnum';

const TicketsPage       = () => import(
    /* webpackChunkName: "Tickets-module" */ 
    '../pages/tickets/TicketsPage.vue'
);
const TicketPage        = () => import(
    /* webpackChunkName: "Tickets-module" */ 
    '../pages/ticket/TicketPage.vue'
);
const TicketData        = () => import(
    /* webpackChunkName: "Tickets-module" */ 
    '../pages/ticket/TicketData/TicketData.vue'
);
const TicketHistoryMap  = () => import(
    /* webpackChunkName: "Tickets-module" */ 
    '../pages/ticket/TicketHistoryMap/TicketHistoryMap.vue'
);

const routes:  Array<RouteRecordRaw> = [
    {
        path: '/tickets',
        name: TicketsRoutesEnum.TICKETS_LIST,
        component: TicketsPage,
        meta: {
            tabTitle: "Tickets list",
			breadCrumbItems: [
				{label: 'Tickets list'},
			]
		}
    },
    {
        path      : '/tickets/:ticketId',
        props     : true,
        component : TicketPage,
        meta: {
            tabTitle: "Ticket detail",
			breadCrumbItems: [
				{
                    label: 'Tickets list', 
                    to: { name: TicketsRoutesEnum.TICKETS_LIST } 
                },
                { label: 'Ticket detail' },
			]
		},
        children: [
            {
                path      : '',
                name      : TicketsRoutesEnum.TICKETS_DETAIL,
                component : TicketData,
            },
            {
                path      : 'history',
                name      : TicketsRoutesEnum.TICKETS_DETAIL_HISTORY,
                component : TicketHistoryMap,
            },
        ]
    },
];

export { routes };
export { TicketsRoutesEnum };