import { CompanyTypeEnum } from '@/model/enums/CompanyTypeEnum';
import { RouteRecordRaw } from 'vue-router';
import { CompaniesRoutesEnum } from './CompaniesRoutesEnum';

const ListPage = () => import(/* webpackChunkName: "Companies-module" */ '../pages/ListPage.vue');
const DetailPage = () => import(/* webpackChunkName: "Companies-module" */ '../pages/DetailPage.vue');

const routes: Array<RouteRecordRaw> = [
	{
		path: '/suppliers',
		name: CompaniesRoutesEnum.SUPPLIERS_LIST,
		component: ListPage,
		props: { type: CompanyTypeEnum.SUPPLIER },
		meta: {
			tabTitle: "Suppliers List",
			breadCrumbItems: [
				{ label: 'Suppliers List' },
			]
		}
	},
	{
		path: '/suppliers/:companyId',
		name: CompaniesRoutesEnum.SUPPLIER_DETAIL,
		props: true,
		component: DetailPage,
		meta: {
			tabTitle: "Supplier detail",
			breadCrumbItems: [
				{ label: 'Suppliers List', to: { name: CompaniesRoutesEnum.SUPPLIERS_LIST } },
				{ label: 'Supplier detail' },
			]
		}
	},
	{
		path: '/customers',
		name: CompaniesRoutesEnum.CUSTOMERS_LIST,
		component: ListPage,
		props: { type: CompanyTypeEnum.CUSTOMER },
		meta: {
			tabTitle: "Customers List",
			breadCrumbItems: [
				{ label: 'Customers List' },
			]
		}
	},
	{
		path: '/customers/:companyId',
		name: CompaniesRoutesEnum.CUSTOMER_DETAIL,
		props: true,
		component: DetailPage,
		meta: {
			tabTitle: "Company detail",
			breadCrumbItems: [
				{ label: 'Customers List', to: { name: CompaniesRoutesEnum.CUSTOMERS_LIST } },
				{ label: 'Customer detail' },
			]
		}
	},
];

export { routes };
export { CompaniesRoutesEnum };