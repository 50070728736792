import { RouteRecordRaw } from 'vue-router';
import { ZonesRoutesEnum } from './ZonesRoutesEnum';

const LoadingSitesPage = () => import(
  /* webpackChunkName: "zones-module" */
  '../pages/loadingSites/LoadingSitesPage.vue'
);
const LoadingSitePage  = () => import(
  /* webpackChunkName: "zones-module" */
  '../pages/loadingSite/LoadingSitePage.vue'
);

const DumpingSitesPage = () => import(
  /* webpackChunkName: "zones-module" */
  '../pages/dumps/DumpsPage.vue'
);
const DumpingSitePage  = () => import(
  /* webpackChunkName: "zones-module" */
  '../pages/dump/DumpPage.vue'
);

const routes:  Array<RouteRecordRaw> = [
    {
        path: '/loading-sites',
        name: ZonesRoutesEnum.LOAD_LIST,
        component: LoadingSitesPage,
        meta	 : {
          tabTitle: 'Loading Site List',
          breadCrumbItems: [
            { label: 'Loading Site List' },
          ]
        }
    },
    {
        path: '/loading-sites/:zoneId',
        name: ZonesRoutesEnum.LOAD_DETAIL,
        props: true,
        component: LoadingSitePage,
        meta	 : {
          tabTitle: 'Loading Site Details',
          breadCrumbItems: [
            {label: 'Loading Site List', to: { name: ZonesRoutesEnum.LOAD_LIST }},
            {label: 'Loading Site Details'},
          ]
        }
    },

    {
        path: '/dumping-sites',
        name: ZonesRoutesEnum.DUMP_LIST,
        component: DumpingSitesPage,
        meta	 : {
          tabTitle: 'Dumping Site list',
          breadCrumbItems: [
            {label: 'Dumping Site list' },
          ]
        }
    },
    {
        path: '/dumping-sites/:zoneId',
        name: ZonesRoutesEnum.DUMP_DETAIL,
        props: true,
        component: DumpingSitePage,
        meta	 : {
          tabTitle: 'Dumping Site Details',
          breadCrumbItems: [
            {label: 'Dumping Site list', to: { name: ZonesRoutesEnum.DUMP_LIST }},
            {label: 'Dumping Site details'},
          ]
        }
    },
];

export { routes };
export { ZonesRoutesEnum };