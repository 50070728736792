import { RouteRecordRaw } from 'vue-router';
import { TextRoutesEnum } from './TextRoutesEnum';

const TextPage  = () => import(
    /* webpackChunkName: "Text-module" */ 
    '../pages/Text/TextPage.vue'
);

const TextListPage  = () => import(
    /* webpackChunkName: "Text-module" */ 
    '../pages/TextList/TextListPage.vue'
);

const routes:  Array<RouteRecordRaw> = [
    {
        path: '/text',
        name: TextRoutesEnum.SEND_TEXT,
        component: TextPage,
		meta	 : {
            tabTitle: 'Send Text',
			breadCrumbItems: [
				{label: 'Send Text'},
			]
		}
    },
    {
        path: '/text-list',
        name: TextRoutesEnum.SENT_TEXTS,
        component: TextListPage,
		meta	 : {
            tabTitle: 'Text list',
			breadCrumbItems: [
				{label: 'Text List'},
			]
		}
    },
];

export { routes };
export { TextRoutesEnum };