import { AuthRoutesEnum } from '@/modules/auth/router';
import { Plugin } from 'vue';
import { localStorageSVC } from './LocalStorage-plugin';

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        /**
         * Indica se è in corso una request bloccante
         */
        requestPending: boolean; 
        
        /**
         * Gestisce una chiamata asincrona "importante"  
         * 
         * Setta requestPending a true prima della chiamata
         * e lo resetta al termine, a prescindere dal risultato.
         * 
         * E' previsto un messaggio d'errore se necessario
         */
        readonly $waitFor: (request: () => Promise<any>, errorMessage?: string, finallyCallback?: () => void) => Promise<void>;
    }
}

const UtilityPlugin: Plugin = {
    install(app) {
        console.debug("Installing Utility plugin...");

        app.mixin({
            data(){
                return {
                    requestPending: false
                }
            }
        })

        app.config.globalProperties.$waitFor =
            async function (request: () => Promise<any>, errorMessage: string = "Operation failed", finallyCallback: () => void) {
                try {
                    this.requestPending = true;
                    
                    await request();

                } catch (error: any) {
                    console.error(error)
                    if ( error?.status === 401 ){
                        localStorageSVC.clearToken();
                        this.$router.replace({ name: AuthRoutesEnum.SIGN_IN });
                    }
                    
                    if ( error?.status === 404 && this.$route?.name?.includes('detail') ){
                        await this.$router.replace({
                            name: this.$route?.name.replace('detail', 'list')
                        });
                    }

                    if ( error?.status === 422 ) {
                        const fieldsInError = Object.keys(error.data.errors);

                        fieldsInError.forEach(k => {
                            this.$errorMessage(error.data.errors[k][0], k.toUpperCase(), 5e3);
                        });

                    } else {
                        this.$errorMessage(errorMessage);
                    }
                } finally {
                    this.requestPending = false;
                    if(finallyCallback){
                        finallyCallback()
                    }
                }
            };
    }
};

export default UtilityPlugin;