import { RouteRecordRaw } from 'vue-router';
import {MaterialsRoutesEnum} from "@/modules/materials/router/MaterialsRoutesEnum";

const MaterialsPage  = () => import(/* webpackChunkName: "Materials-module" */ '../pages/materials/MaterialsPage.vue');
const MaterialPage  = () => import(/* webpackChunkName: "Materials-module" */ '../pages/material/MaterialPage.vue');

const routes:  Array<RouteRecordRaw> = [
    {
        path: '/materials',
        name: MaterialsRoutesEnum.MATERIALS_LIST,
        component: MaterialsPage,
		meta	 : {
			tabTitle: "Materials List",
			breadCrumbItems: [
				{label: 'Materials List'},
			]
		}
    },
	{
		path     : '/materials/:materialId',
		name     : MaterialsRoutesEnum.MATERIALS_DETAIL,
		props    : true,
		component: MaterialPage,
		meta	 : {
			tabTitle: "Material detail",
			breadCrumbItems: [
				{label: 'Material List', to: { name: MaterialsRoutesEnum.MATERIALS_LIST }},
				{label: 'Material detail'},
			]
		}
	},
];

export { routes };
export { MaterialsRoutesEnum };
