{
  "common": {
    "messages": {
      "create_success": "created successfully",
      "update_success": "updated successfully",
      "update_fail": "update failed",
      "delete_confirm": "Are you sure to delete this",
      "delete_success": "deleted successfully"
    },
    "actions": "Actions",
    "edit": "Edit",
    "yes": "Yes",
    "clear": "Clear",
    "apply": "Apply",
    "no": "No",
    "free": "free",
    "delete": "Delete",
    "search_placeholder": "Search",
    "loading": "Loading...",
    "no_records_found": "No records found",
    "refresh": "Refresh",
    "reset": "Reset"
  },
  "ticket": {
    "singular": "Ticket",
    "plural": "Tickets",
    "title_edit": "@:ticket.singular Details",
    "list": {
      "title": "Ticket list"
    },
    "id": "@:ticket.singular Id",
    "name": "Full Name",
    "status": "Status",
    "cost_code": "Cost code",
    "materials": "Materials",
    "start_date": "Start Date",
    "start_time": "Start time",
    "free_dump_requested_at": "Free dump requested",
    "free_dump_accepted_at": "Free dump accepted",
    "end_date": "End Date",
    "end_time": "End time",
    "cycles": "Cycles",
    "total_ton": "Total Ton",
    "duration": "Duration",
    "fee": "Fee",
    "final_status": "Final Status",
    "finish_note": "Finish Note",
    "dispute_note": "Dispute Note",
    "payment_statuses": {
      "PENDING": "Pending",
      "AUTHORIZED": "Authorized",
      "PAID": "Paid"
    },
    "statuses": {
      "ACCEPTED_BY_USER": "Accepted by user",
      "DISPUTE": "Dispute",
      "DUMPED": "Dumped",
      "LOADED": "Loaded",
      "WAITING_DUMP": "Waiting Dump",
      "WAITING_LOAD": "Waiting Load",
      "CLOSED": "Closed",
      "DECLINED": "Declined",
      "DELETED_BY_SYSTEM": "Deleted by System",
      "DELETED_BY_USER": "Deleted by User"
    },
    "finish_status": {
      "10": "Completed cycles",
      "20": "Truck Issue",
      "30": "Tip site issue",
      "40": "Client cancelled",
      "50": "Health & Safety Issue",
      "60": "Site Access",
      "100": "Other",
      "200": "System"
    },
    "messages": {
      "update_success": "@:ticket.singular @:common.messages.update_success",
      "update_fail": "@:ticket.singular @:common.messages.update_fail",
      "dispute_resolved": "@:ticket.singular dispute resolved",
      "close_success": "@:ticket.singular closed successfully",
      "delete_confirm": "@:common.messages.delete_confirm Ticket ?"
    },
    "tabs": {
      "data": "@:ticket.singular data",
      "tiket_history": "@:ticket.singular history"
    },
    "table": {
      "id": "@:ticket.id",
      "name": "@:ticket.name",
      "status": "@:ticket.status",
      "start_date": "@:ticket.start_date",
      "end_date": "@:ticket.end_date",
      "duration": "@:ticket.duration",
      "cycles": "@:ticket.cycles",
      "fee": "@:ticket.fee",
      "final_status": "@:ticket.final_status",
      "payment_status": "Payment status",
      "cost_code": "@:ticket.cost_code",
      "zone_load": "@:shift.load_site",
      "dump_site": "@:shift.dump_site",
      "job_site": "@:shift.job_site.label",
      "materials": "@:ticket.materials",
      "filters": {
        "by_status": "Filter by status",
        "by_materials": "Filter by materials",
        "by_cost_code": "Filter by cost code",
        "by_job_site": "Filter by job site",
        "by_shift_name": "Filter by shift name"
      },
      "shift": "Work Request"
    },
    "history": {
      "title": "History",
      "ton": "Ton",
      "ton_photo": "Ton photo",
      "duration": "@:ticket.duration",
      "time": "Time",
      "driver_notes": "Driver Notes"
    },
    "actions": {
      "force_closure": "Force closure",
      "resolve_dispute": "Resolve Dispute",
      "delete": "Delete"
    },
    "data": "Ticket Data",
    "payment": "Payment",
    "payment_status": "Status",
    "shift_data": "Work Request",
    "shift_name": "Work Request Name"
  },
  "profile": {
    "title": "Profile Details",
    "first_name": "First Name",
    "last_name": "Last Name",
    "email": "E-mail",
    "confirm_password": "Confirm Password",
    "password": "Password",
    "messages": {
      "update_success": "Profile @:common.messages.update_success"
    }
  },
  "dashboard": {
    "todays_shifts": "Today's Work Requests"
  },
  "contract": {
    "singular": "Contract",
    "plural": "Contracts",
    "title_new": "New @:contract.singular",
    "title_edit": "Edit @:contract.singular",
    "list": {
      "title": "Contract Type"
    },
    "name": "Name",
    "notes": "Notes",
    "price_hour": "Price per hour",
    "price_cycle": "Price per cycle",
    "price_ton": "Price per ton",
    "distance_dz_on": "Distance to Dump Zone Onsite",
    "distance_dz_off": "Distance to Dump Zone Offsite",
    "min_trucks": "Minimum Trucks:",
    "min_hours_cancellation": "Minimum Hours for late cancellations by client:",
    "failure_options": "Option: Truck failure to arrive or cancel by trucker penalty",
    "messages": {
      "create_success": "@:contract.singular @:common.messages.create_success",
      "update_success": "@:contract.singular @:common.messages.update_success",
      "delete_confirm": "@:common.messages.delete_confirm @:contract.singular ?",
      "delete_success": "@:contract.singular @:common.messages.delete_success"
    }
  },
  "material": {
    "singular": "Material",
    "plural": "Materials",
    "name": "Name",
    "notes": "Notes",
    "title_new": "New @:material.singular",
    "title_edit": "Edit @:material.singular",
    "list": {
      "title": "Material List"
    },
    "table": {
      "name": "Material Name",
      "notes": "Notes"
    },
    "messages": {
      "create_success": "@:material.singular @:common.messages.create_success",
      "update_success": "@:material.singular @:common.messages.update_success",
      "delete_confirm": "@:common.messages.delete_confirm @:material.singular ?",
      "delete_success": "@:material.singular @:common.messages.delete_success"
    }
  },
  "login": {
    "title": "Sign in",
    "subtitle": "Enter your credentials to log in",
    "username_label": "Username",
    "username_placeholder": "username",
    "password_label": "Password",
    "password_placeholder": "password",
    "error_message": "Wrong credentials. \nPlease check and try again.",
    "forget_pwd": "Forgot password ?",
    "sign_up": "Sign in"
  },
  "reset_password": {
    "new_password": "New Password",
    "confirm_password": "Confirm Password"
  },
  "lost_password": {
    "email_sent_message": "A link and instructions to reset password was sent to your e-mail.",
    "check_inbox_to_continue": "Check your email to continue",
    "enter_email_message": "Enter your email to proceed by reset password ",
    "email": "E-mail",
    "send_email": "Send E-mail"
  },
  "app_shift": {
    "singular": "App Work Request",
    "plural": "App Work Requests",
    "title_new": "New App Work Request",
    "title_detail": "App Work Request",
    "status": "Status",
    "user": "User",
    "created_at": "Created at",
    "created_shift": "Created shift",
    "job_site": "Job Site",
    "cost_code": "Cost code",
    "shift_reference": "Shift reference",
    "poc_name": "Poc Name",
    "email": "POC Email",
    "phone": "POC phone",
    "material": "Material",
    "material_state": "@:app_shift.material state",
    "material_other": "Other @:app_shift.material",
    "load_type": "Load type",
    "load_type_other": "@:app_shift.load_type other",
    "is_use_load_w3": "Use 3W",
    "w3load": "3W",
    "load_driver_note": "Driver note",
    "load_city": "City",
    "load_address": "Address",
    "load_zip": "Zip",
    "load_state": "State",
    "is_dump_same_load_site": "Same load site",
    "is_use_dump_w3": "@:app_shift.is_use_load_w3",
    "w3dump": "@:app_shift.w3load",
    "dump_driver_note": "@:app_shift.load_driver_note",
    "dump_city": "@:app_shift.load_city",
    "dump_address": "@:app_shift.load_address",
    "dump_zip": "@:app_shift.load_zip",
    "dump_state": "@:app_shift.load_state",
    "date": "Start date",
    "start_datetime": "Start time",
    "end_datetime": "End time",
    "zone_name": "Timezone",
    "staggered_start_mins": "Staggered start minutes",
    "contract_name": "Contract name",
    "payment_type": "Payment type",
    "price_hour": "Price Hour",
    "price_cycle": "Price cycle",
    "price_ton": "Price ton",
    "trucks_min": "Min trucks",
    "trucks_max": "Max trucks",
    "drivers_note": "Generic @:app_shift.load_driver_note",
    "list": {
      "title": "@:app_shift.plural"
    },
    "payment_types": {
      "CYCLE": "Cycle",
      "HOUR": "Hour",
      "TON": "Ton"
    },
    "statuses": {
      "PENDING": "Pending",
      "APPROVED": "Approved",
      "REJECTED": "Rejected"
    },
    "messages": {
      "delete_confirm": "@:common.messages.delete_confirm @:app_shift.singular ?",
      "delete_success": "@:app_shift.singular @:common.messages.delete_success",
      "approve_success": "@:app_shift.singular successfully approved",
      "reject_success": "@:app_shift.singular successfully rejected"
    }
  },
  "shift": {
    "singular": "Work Request",
    "plural": "Work Requests",
    "title_new": "New Work Request Sheet",
    "title_detail": "Work Request Sheet",
    "code": "Shift Name",
    "description": "Work Description",
    "active_expiration": "Active Expiration",
    "expire_time": "Expire Time",
    "poc_phone": "POC Phone Number",
    "poc_email": "POC E-mail",
    "material": "Material",
    "private": "Private Shift",
    "private_contract": {
      "label": "Private shift",
      "placeholder": "Visible for contract users"
    },
    "whitelist_users": "Whitelist users",
    "add_user": "Add User",
    "add_user_extended": "@:shift.add_user to Whitelist",
    "cost_code": "Cost Code",
    "free_dump": "Free dump",
    "public_note": "Driver Notes (Public)",
    "is_dump_same_load_site": "Same load site",
    "private_note": "Admin Notes (Private)",
    "load_site": "Load Site (LS)",
    "dump_site": "Dump Site (DS)",
    "distance": "Distance (miles)",
    "start_date": "Start date",
    "start_time": "Start time",
    "pause_minutes": "Break (mins)",
    "list": {
      "title": "@:shift.plural"
    },
    "table": {
      "name": "Shift Name",
      "start_datetime": "Start Date",
      "end_datetime": "End Date",
      "dump_zone": "Dump Site",
      "load_zone": "Load Site"
    },
    "tabs": {
      "data": "WRS Details",
      "tiket_list": "Shift Status"
    },
    "duplicated_message": "Duplicated successfully",
    "delete_message_failed": "Deleting failed",
    "delete_message": "Confirm that you want to delete this shift by pressing Yes",
    "start_date_time": "Start Time",
    "end_time": "End time",
    "job_site": {
      "label": "Job site",
      "placeholder": "Search @:shift.job_site.label"
    },
    "poc_name": {
      "label": "POC Name",
      "placeholder": "Search @:shift.poc_name.label"
    }
  },
  "user": {
    "singular": "User",
    "plural": "Users",
    "title_new": "New @:user.singular",
    "title_edit": "@:user.singular details",
    "list": {
      "title": "@:user.singular list"
    },
    "first_name": "First Name",
    "last_name": "Last Name",
    "email": "Work E-mail",
    "phone": "Work Phone",
    "role": "Role",
    "city": "City",
    "address": "Home Address",
    "zip": "ZIP",
    "truck_info": "Type of truck",
    "truck_tag": "Truck Tag",
    "driver_license": "Driver's License",
    "cdl_license": "CDL License",
    "company_name": "Company Name",
    "usdot_number": "USDOT Number",
    "status": "Status",
    "contract": "Contract",
    "private_note": "Private note",
    "emergency_contact_title": "Emergency Contact",
    "emergency_email": "E-mail",
    "emergency_phone": "Telephone",
    "emergency_name": "Name",
    "table": {
      "first_name": "@:user.first_name",
      "last_name": "@:user.last_name",
      "email": "E-mail",
      "phone": "Telephone",
      "role": "@:user.role",
      "status": "Status",
      "text": "Text"
    },
    "role_10": "USER",
    "role_20": "OWNER OPERATOR",
    "role_100": "ADMIN",
    "delete_message": "Confirm that you want to delete this user by pressing Yes",
    "status_10": "CREATED",
    "status_20": "ACTIVE",
    "status_30": "DELETED",
    "status_40": "SELF DELETED"
  },
  "admin": {
    "singular": "Admin",
    "plural": "Admins",
    "title_new": "New @:admin.singular",
    "title_edit": "@:admin.singular Details",
    "list": {
      "title": "@:admin.singular list"
    },
    "is_user_free_dump_req": "User for free dump requests",
    "first_name": "First Name",
    "last_name": "Last Name",
    "email": "E-mail",
    "table": {
      "first_name": "@:admin.first_name",
      "last_name": "@:admin.last_name",
      "email": "@:admin.email",
      "is_user_free_dump_req": "User for free dump req."
    }
  },
  "loading_site": {
    "singular": "Loading Site",
    "plural": "Loading Sites",
    "title_new": "New Load Site",
    "title_edit": "Edit Load Site",
    "name": "Name",
    "info": "Details",
    "public_notes": "Public notes",
    "private_notes": "Private notes",
    "gallery": "Gallery",
    "reset_shape": "Reset Shape",
    "contact_person": "Contact Person",
    "contact_phone": "Contact Phone",
    "list": {
      "title": "Loading Site List"
    },
    "table": {
      "name": "@:loading_site.name",
      "w3": "W3",
      "info": "@:loading_site.info",
      "public_notes": "Note",
      "contact_person": "@:loading_site.contact_person",
      "contact_phone": "@:loading_site.contact_phone",
      "filters": {
        "by_name": "Search by name",
        "by_w3": "Search by W3"
      }
    },
    "messages": {
      "create_success": "@:loading_site.singular @:common.messages.create_success",
      "update_success": "@:loading_site.singular @:common.messages.update_success",
      "update_fail": "@:loading_site.singular @:common.messages.update_fail",
      "delete_confirm": "@:common.messages.delete_confirm @:loading_site.singular ?",
      "delete_success": "@:loading_site.singular @:common.messages.delete_success"
    }
  },
  "dumping_site": {
    "singular": "Dumping Site",
    "plural": "Dumping Sites",
    "title_new": "New Dump Site",
    "title_edit": "Edit Dump Site",
    "name": "@:loading_site.name",
    "info": "@:loading_site.info",
    "public_notes": "@:loading_site.public_notes",
    "private_notes": "@:loading_site.private_notes",
    "reset_shape": "@:loading_site.reset_shape",
    "contact_person": "@:loading_site.contact_person",
    "contact_phone": "@:loading_site.contact_phone",
    "list": {
      "title": "Dumping Site List"
    },
    "table": {
      "name": "@:loading_site.table.name",
      "w3": "@:loading_site.table.w3",
      "info": "@:loading_site.table.info",
      "public_notes": "@:loading_site.table.public_notes",
      "contact_person": "@:loading_site.contact_person",
      "contact_phone": "@:loading_site.contact_phone",
      "filters": {
        "by_name": "Search by name",
        "by_w3": "Search by W3"
      }
    },
    "messages": {
      "create_success": "@:dumping_site.singular @:common.messages.create_success",
      "update_success": "@:dumping_site.singular @:common.messages.update_success",
      "update_fail": "@:dumping_site.singular @:common.messages.update_fail",
      "delete_confirm": "@:common.messages.delete_confirm @:dumping_site.singular ?",
      "delete_success": "@:dumping_site.singular @:common.messages.delete_success"
    }
  },
  "table": {
    "messages": {
      "no_records_found": "No records found.",
      "loading": "Loading..."
    },
    "search_placeholder": "search..."
  },
  "duplicate_shift_dialog": {
    "title": "Duplicate Work Request",
    "new_code": "New Shift Name",
    "new_code_placeholder": "Name for cloned shift"
  },
  "driver_summary": {
    "title": "Driver Details",
    "last_name": "Last Name",
    "first_name": "First Name",
    "phone": "Telephone",
    "email": "E-mail"
  },
  "text": {
    "title": "Compose message",
    "message": {
      "title": "Title",
      "title_placeholder": "@:text.message.title",
      "text": "Body",
      "text_placeholder": "@:text.message.title"
    },
    "list": {
      "title": "Sent messages"
    }
  },
  "role.10": "User",
  "role.100": "Super admin",
  "dumps.list.title": "Dumping Site List",
  "zone.type_10": "Loading",
  "zone.type_20": "Dump",
  "timezone.label": "Timezone",
  "timezone.placeholder": "Select a timezone",
  "state_autocomplete.label": "What state do you live in",
  "user_autocomplete.label": "Search User",
  "user_autocomplete.placeholder": "Search User",
  "w3_autocomplete.label": "Search w3w",
  "zone_autocomplete.placeholder": "Search site",
  "message.confirm": "Confirmation request",
  "message.sms": "Sms",
  "message.email": "E-mail",
  "message.push": "Push",
  "validation.required": "Field required",
  "validation.pwd_and_confirm_match": "Password and Confirm password must match",
  "save": "Save",
  "delete": "Delete",
  "back": "Back",
  "edit": "Edit",
  "loading": "Loading...",
  "cancel": "Cancel",
  "resolve dispute": "Resolve Dispute",
  "calc": "Calc",
  "send_message": "Send message",
  "force_closure": "Force closure",
  "resolve_dispute": "Resolve dispute",
  "export": "Export",
  "Loading Failed": "Loading failed",
  "show_image": "Show Image",
  "duplicate": "Duplicate",
  "confirm": "Confirm",
  "hours": "hour | hours",
  "minutes": "minute | minutes",
  "open": "Open",
  "remove": "Remove",
  "In progress": "In progress",
  "Operation failed": "Operation failed",
  "contract.table.name": "Contract Name",
  "contract.table.price_per_cycle": "@:contract.price_cycle",
  "contract.table.price_per_hour": "@:contract.price_hour",
  "contract.table.price_per_ton": "Price per ton",
  "contract.table.notes": "Notes",
  "god_mode.title": "Live view "
}
